import {
  Box,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Image,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import React from "react";
import Layout from "../components/Layout";

const Books = () => {
  return (
    <Layout>
      <Box pt={20}>
        <Center>
          <Heading fontFamily={'merriweather'} pb={20}>BOOKS & BLOGS</Heading>
        </Center>
        <SimpleGrid columns={{base : "1", md : "2"}} spacingX="40px" spacingY="50px" mx={10} p={10}>
          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_400,w_400/v1666029200/AAC-web/books/WhatsApp_Image_2022-10-17_at_16.29.01_oeqock.jpg"
                }
              />
              <Center mt={4}>
                <Button 
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="OPEN"
                    name={"MACHINE LEARNING IN A NUTSHELL"}
                    author={
                      "I am thrilled that you made time to know about me. To start off with, “I scream, you scream, we all scream for Ice-Cream”, Ice-Cream is my valentine, it is something I can’t live without. People call me a photo freak as I try to capture every moment and everything that fascinates me. I am an avid traveler and an adventure seeker. A very approachable and garrulous person so never hesitate to break the ice. So, this is all about me “Shruthi Gutta 16241A04H3shruthigutta05@gmail.com"
                    }
                    bookdetails={
                      "We live in a world which takes a new shape everyday with many people trying to sculpt it into a place where we can live very comfortably. So to squeeze my way into this technologically advanced society I wanted to build a unique skill set. In this process I came across IoT and got to know the true power of what it can do. So I started off by reading a few articles, browsing the Internet and by going through all the material that I had access to on this topic. Soon I realized that whatever I had gone through is all scrambled without any path connecting each other. Then I came up with the idea of connecting all these dots and making a path for my juniors with all the knowledge I have on IoT. They say that the sky is the limit but in IoT these is no sky, the only limit is your imagination."
                    }
                    image={
                      "https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_300,w_300/v1666029200/AAC-web/books/WhatsApp_Image_2022-10-17_at_16.29.01_oeqock.jpg"
                    }
                  />
                </Button>
              </Center>
            </Center>
          </Center>

          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_400,w_400/v1666201705/AAC-web/books/website_le9wrb.png"
                }
              />
              <Center mt={4}>
                <Button 
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="OPEN"
                    name={"STEPPING INTO AN EFFORTLESS TOMORROW – IOT"}
                    author={
                      "I am thrilled that you made time to know about me. To start off with, “I scream, you scream, we all scream for Ice-Cream”, Ice-Cream is my valentine, it is something I can’t live without. People call me a photo freak as I try to capture every moment and everything that fascinates me. I am an avid traveler and an adventure seeker. A very approachable and garrulous person so never hesitate to break the ice. So, this is all about me “Shruthi Gutta 16241A04H3shruthigutta05@gmail.com"
                    }
                    bookdetails={
                      "We live in a world which takes a new shape everyday with many people trying to sculpt it into a place where we can live very comfortably. So to squeeze my way into this technologically advanced society I wanted to build a unique skill set. In this process I came across IoT and got to know the true power of what it can do. So I started off by reading a few articles, browsing the Internet and by going through all the material that I had access to on this topic. Soon I realized that whatever I had gone through is all scrambled without any path connecting each other. Then I came up with the idea of connecting all these dots and making a path for my juniors with all the knowledge I have on IoT. They say that the sky is the limit but in IoT these is no sky, the only limit is your imagination."
                    }
                    image={
                      "https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_300,w_300/v1666201705/AAC-web/books/website_le9wrb.png"
                    }
                  />
                </Button>
              </Center>
            </Center>
          </Center>

          <Center>
            <Center display={"flex"} flexDirection={"column"}>
              <Cards
                img={
                  "https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_400,w_400/v1666029211/AAC-web/books/WhatsApp_Image_2022-10-17_at_16.29.01_msvubl.jpg"
                }
              />
              <Center mt={4}>
                <Button 
                  fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
                >
                  <Basicmodal
                    text="OPEN"
                    name={
                      "OBJECT ORIENTED PROGRAMMING LANGUAGE THROGH JAVA FOR BEGINNERS"
                    }
                    author={
                      "I am thrilled that you made time to know about me. To start off with, “I scream, you scream, we all scream for Ice-Cream”, Ice-Cream is my valentine, it is something I can’t live without. People call me a photo freak as I try to capture every moment and everything that fascinates me. I am an avid traveler and an adventure seeker. A very approachable and garrulous person so never hesitate to break the ice. So, this is all about me “Shruthi Gutta 16241A04H3shruthigutta05@gmail.com"
                    }
                    bookdetails={
                      "We live in a world which takes a new shape everyday with many people trying to sculpt it into a place where we can live very comfortably. So to squeeze my way into this technologically advanced society I wanted to build a unique skill set. In this process I came across IoT and got to know the true power of what it can do. So I started off by reading a few articles, browsing the Internet and by going through all the material that I had access to on this topic. Soon I realized that whatever I had gone through is all scrambled without any path connecting each other. Then I came up with the idea of connecting all these dots and making a path for my juniors with all the knowledge I have on IoT. They say that the sky is the limit but in IoT these is no sky, the only limit is your imagination."
                    }
                    image={
                      "https://res.cloudinary.com/aacgriet/image/upload/c_scale,h_300,w_300/v1666029211/AAC-web/books/WhatsApp_Image_2022-10-17_at_16.29.01_msvubl.jpg"
                    }
                  />
                </Button>
              </Center>
            </Center>
          </Center>
        </SimpleGrid>
      </Box>
    </Layout>
  );
};

export default Books;

const Cards = ({ img }) => {
  return (
    <Box
      maxW={{ base: "200px", sm: "250px", md: "300px" }}
      boxShadow={"2xl"}
      rounded={"lg"}
      pos={"relative"}
      zIndex={1}
      _hover={{
        cursor: "pointer",
        transition: "all .3s ease",
        transform: "scale(1.03)",
      }}
    >
      <Center>
        <Image
          rounded={"xl"}
          height={"20rem"}
          width={"20rem"}
          boxSize="100%"
          src={img}
          boxShadow={"2xl"}
        />
      </Center>
    </Box>
  );
};

function Basicmodal({ text, name, author, bookdetails,image }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Center onClick={onOpen}>{text}</Center>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        size={"6xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader align={"center"}>
            <Heading fontFamily={'merriweather'} mt={8}>
            {name}
            </Heading>
            </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Center display={"flex"} flexDirection={{base : "column", md : "row"}}>
              <Image src={image} rounded={"lg"}/>
              <Box fontFamily={'poppins'} fontWeight="bold" mb="1rem" m={10}>
                <Center>
                <Heading fontWeight={"medium"} m={8}>Author</Heading>
                </Center>
                {author}
                <br />
                <br />
                <Center>
                <Heading fontWeight={"medium"} m={8}>About the Book</Heading>
                </Center>
                {bookdetails}
              </Box>
            </Center>
          </ModalBody>
          <ModalFooter>
            <Button 
              fontSize={"sm"}
                  rounded={"full"}
                  bg={"blue.400"}
                  color={"white"}
                  boxShadow={
                    "0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)"
                  }
                  _hover={{
                    bg: "blue.500",
                  }}
                  _focus={{
                    bg: "blue.500",
                  }}
            mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
